import skryv from '@skryv/core-ng1';
import { translation, theme } from '@skryv/core-ng1';

import logo from './theme/assets/logo-small-color.png';

window.useESWithFGAs = true;

theme.logo(logo, 'eGovFlowDIY');

translation
  .locale('nl_BE');

skryv
  .customize(theme)
  .customize(translation)
  .initialize(document.getElementById('root'));
